<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <a-form-model :rules="rules" :form="form" ref="ruleForm" :model="form" @ok="handleOk">
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item ref="customerId" prop="customerId" label="客户名称">
              <a-select v-model="form.customerId" style="width: 100%" placeholder="请选择客户"
                :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                show-search :default-value="null" option-label-prop="label">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData"
                  :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="sellerId" prop="sellerId" label="卖方主体">
              <a-select v-model="form.sellerId" style="width: 100%" placeholder="请选择卖方主体">
                <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="invoiceAmount" prop="invoiceAmount" label="合计开票金额">
              <div style="position: relative;">
                <a-input v-model="form.invoiceAmount" placeholder="请输入合计开票金额" @blur="handleBlur" />
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="M+" ref="businessExpenses" prop="businessExpenses">
              <div style="position: relative;">
                <a-input v-model="form.businessExpenses" placeholder="请输入M+" @blur="handleBlur" />
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item label="预期出库时间">
              <a-date-picker v-if="this.$route.query.pageType == 'copy'" style="width: 100%;" @change="dateChange"
                :format="dateFormat" />

              <a-date-picker v-else style="width: 100%;" @change="dateChange"
                :default-value="form.sellingTime ? moment(formatDate(form.sellingTime), dateFormat) : null"
                :format="dateFormat" />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item label="预计回款时间">
              <a-date-picker v-if="this.$route.query.pageType == 'copy'" style="width: 100%;" @change="hkChange"
                :format="dateFormat" />
              <a-date-picker v-else style="width: 100%;" @change="hkChange"
                :default-value="form.expectReturnTime ? moment(formatDate(form.expectReturnTime), dateFormat) : null"
                :format="dateFormat" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="项目等级" required>
              <a-select style="width: 76%"
                  placeholder="请选择项目等级"
                  v-model="form.projectType"
                  @change="handlelevelChange"  
                  >
                  <a-select-option :value="item.id" :label="item.name"
                      v-for=" (item, index) in [{ id: 1, name: '投标' }, { id: 2, name: '非投标' }]"
                      :key="index">
                      {{ item.name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="回款方式" required>
              <a-select style="width: 76%"
                  placeholder="请选择回款方式"
                  v-model="form.repayType"
                  @change="handlewaylChange"  
                  >
                  <a-select-option :value="item.id" :label="item.name"
                      v-for=" (item, index) in [{ id: 1, name: '电汇' }, { id: 2, name: '支票' }, { id: 3, name: '现金' }, { id: 4, name: '其他' }]"
                      :key="index">
                      {{ item.name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 1 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24" v-if="form.sfaSaleContract">
            <a-form-model-item label="是否需要签订合同" required>
              <a-radio-group @change="changeType" v-model="form.sfaSaleContract.type">
                <a-radio :value="2"> 需要签订 </a-radio>
                <a-radio :value="1"> 无需签订 </a-radio>
              </a-radio-group>
              <div v-if="form.sfaSaleContract.type == 2" style="display: flex;align-items: center;">
                <div style="display:flex;">
                  <span>签订合同份数：</span>
                  <a-select v-model="form.sfaSaleContract.quantity" style="width: 70px" placeholder="">
                    <a-select-option :value="item" v-for="item in 10" :key="item">
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </div>
                <div style="display:flex;margin-left:5px;">
                  <span>需回合同份数：</span>
                  <a-select v-model="form.sfaSaleContract.revertQuantity" style="width: 70px" placeholder="">
                    <a-select-option :value="item" v-for="item in 10" :key="item">
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
              <div v-if="form.sfaSaleContract.type == 1" style="display: flex;align-items:center">
                <span style="width:50px;">备注：</span>
                <a-input placeholder="请输入" v-model="form.sfaSaleContract.notes"></a-input>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="6" :md="12" :sm="24">
          <a-form-model-item required label="发票">
            <a-select  v-model="form.invoice" placeholder="请选择是否需要发票" @change="invoiceChange" option-label-prop="label">
              <a-select-option :value="1" label="开票">开票</a-select-option>
              <a-select-option :value="2" label="不开票">不开票</a-select-option>
              <a-select-option :value="3" label="未知">未知</a-select-option>
            </a-select>
        </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 6, offset: 1 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <div class="price-box">
              <div style="border-right: 1px solid #EBEAED;">
                <p>合计实际收款</p>
                <span>{{ getAmount() }}元</span>
              </div>
              <div>
                <p>平均折扣:</p>
                <span v-if="form.averageDiscount">{{ form.averageDiscount }}</span> <span v-else>0</span> 折
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="回款客户名称" required>
              <a-input-group compact>
                <a-select :default-value="0" v-model="returnCustomerType" style="width: 100px">
                  <a-select-option :value="0">
                    搜索公司
                  </a-select-option>
                  <a-select-option :value="1">
                    手动输入
                  </a-select-option>
                </a-select>

                <a-select style="width: 50%" show-search v-if="returnCustomerType == 0" :value="searchReturnName"
                  placeholder="请输入客户名称" :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                  :not-found-content="null" @search="fetchUserReturn" @change="handleSearchReturnChange">
                  <a-select-option v-for="d in customerReturnData" :key="d.name" :label="d.name" :value="d.name">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
                <a-input style="width: 50%" v-else placeholder="请输入客户名称" v-model="form.returnCustomerName" />
                <a-button style="display: inline-block;" @click="addCusomer" type="primary">添加</a-button>
              </a-input-group>
              <div class="lists" v-for="(item,index) in cusomerData" :key="index" >
                  {{item}}
                  <span style="color:#5542f6;cursor: pointer;" @click="deleteCusomer(item)">移除</span>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <card v-if="form.sfaSaleDocketProductList.length > 0" ref="myCard" :totalAmount="form.amount"
      @handleObject="handleObject" @handleDiscount="handleDiscount" type="edit"
      :detailsArr="form.sfaSaleDocketProductList" :isBackShow="false"></card>
    <!-- <card ref="myCard" :totalAmount="form.amount" @handleObject="handleObject" @handleDiscount="handleDiscount"
      type="edit" :detailsArr="form.sfaSaleDocketProductList" :isBackShow="false"></card> -->

    <footer-tool-bar>
      <a-button type="primary" style="margin-right: 20px;background: none;border: 1px solid #999999;color: #999999;"
        @click="handleOk(1)" :loading="loading">{{ $t('存草稿') }}</a-button>
      <a-button type="primary" @click="handleOk(4)" :loading="loading">{{ $t('提交审核') }}</a-button>
    </footer-tool-bar>
  </div>
</template>

<script>
import moment from 'moment';
import { request } from '@/utils/request'
import FooterToolBar from '@/components/tool/FooterToolBar'
import card from './components/card.vue'
export default {
  name: 'addForm',
  components: { FooterToolBar, card },
  data() {
    this.lastFetchId = 0;
    this.lastReturnFetchId = 0;
    // this.fetchUser = debounce(this.fetchUser, 900);
    // this.fetchUserReturn = debounce(this.fetchUserReturn, 900);
    return {
      dateFormat: "YYYY-MM-DD",
      loading: false,
      form: {
        invoiceAmount: '',
        businessExpenses: '',
        amount: '',
        sfaSaleContract: {
          notes:'暂时无需签订合同',
          type: 2
        }
      },

      users: [],
      rules: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        sellerId: [{ required: true, message: '请选择卖方主体', trigger: 'change' }],
        invoiceAmount: [{ required: true, message: '请输入合计开票金额', trigger: 'blur' }],
        businessExpenses: [{ required: true, message: '请输入M+', trigger: 'blur' }],
        sellingTime: [{ required: true, message: '请选择预期出库时间', trigger: 'change' }],
      },
      describeText: "",
      details: {},
      customerReturnData: [],//回款客户
      cusomerData:[],
      searchReturnName:[],
      customerData: [],
      sellerList: [],//卖方主体
      expectReturnTime: "",
      returnCustomerType: 1
    }
  },
  mounted() {
    // 详情
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/' + this.$route.query.id, 'get').then(res => {
      this.form = res.data.data;
      this.cusomerData = this.form.returnCustomerName.split(',')
      this.form.returnCustomerName = ''
      // 复制：默认不展示售卡日期
      if (this.$route.query.pageType == 'copy') {
        this.form.sellingTime = ""
      }
      // let invoiceAmount = 0;
      // 复制默认不展示每条激活、冻结等状态
      this.form.sfaSaleDocketProductList.forEach(item => {
        item.sfaCardCouponList.forEach(it => {
          if (this.$route.query.pageType == 'copy') {
            delete it.outBatchCode
            delete it.actStatus
            delete it.actStatusName
            delete it.frozenStatusName
            delete it.frozenStatus
            delete it.outStatus
            delete it.outStatusName
          }
        })
        console.log(this.form, 'form')
      })
    })

    if (!this.form.sfaSaleContract) {
      this.form.sfaSaleContract = {
        type: 2,
      }
    }
    // this.$set(this.form.notes, 'revertQuantity', "");
    // 客户列表
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list/all', 'get').then(res => {
      this.customerData = res.data.data
    })
    // 卖方主体
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
      this.sellerList = res.data.data;
    })
  },
  methods: {
    fetchUserReturn(value) {
      if (!value) {
        return false;
      }
      this.lastReturnFetchId += 1;
      const fetchId = this.lastReturnFetchId;
      this.customerReturnData = [];
      this.fetching = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/findEnterpriseByName/'+value, 'get', ).then(res => {
        if (fetchId !== this.lastReturnFetchId) {
          return;
        }
        this.customerReturnData = res.data.data;
        this.fetching = false;
      }).catch(error => {
        console.error('Error fetching user:', error);
      });
    },
    handleSearchReturnChange(searchVal) {
      console.log(searchVal, 'val')
      this.form.returnCustomerName = searchVal;
      let searchReturnName = searchVal
      Object.assign(this, {
        searchReturnName,
        fetching: false,
      });
    },
    changeType(val){
      if(val.target.value == 1){
        this.form.sfaSaleContract.notes = '暂时无需签订合同' 
      }else{
        this.form.sfaSaleContract.notes = ''
      }
    },
    handleBlur() {
      this.$refs.myCard.calcDiscount();
    },
    getAmount() {
      // businessExpenses业务支出     invoiceAmount 开票金额
      this.form.amount = this.form.invoiceAmount - this.form.businessExpenses
      return this.form.invoiceAmount - this.form.businessExpenses
    },
    handleDiscount(discount) {
      this.form.averageDiscount = discount
      this.$set(this.form, 'averageDiscount', discount);
      console.log(this.form.averageDiscount, 'aver')
    },
    moment,
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toISOString().split('T')[0];
    },
    hkChange(date, dateString) {
      this.form.expectReturnTime = dateString;
      this.expectReturnTime = dateString
    },
    // 选择日期
    dateChange(date, dateString) {
      this.form.sellingTime = dateString
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    back() {
      this.$router.replace('/sfa_workOrder/OutboundList')
    },
    handleObject(data) {
      let amount = 0;
      data.forEach(item => {
        if (item.sfaCardCouponList.length > 0) {
          item.sfaCardCouponList.forEach(it => {
            amount = amount + (it.amount * it.number)
          })
        }
        this.form.amount = amount;
      })
      this.form.sfaSaleDocketProductList = data;
      console.log(this.form, 'data')
    },
    handlelevelChange(val){
      this.form.projectType = val
    },
    invoiceChange(val){
      this.form.invoice = val
    },
    handlewaylChange(val){
      this.form.repayType = val
    },
    addCusomer(){
      if(this.form.returnCustomerName){
        this.cusomerData.push(this.form.returnCustomerName)
      }
      this.form.returnCustomerName = ''
    },
    deleteCusomer(val){ 
      this.cusomerData = this.cusomerData.filter(item => item !== val);
    },
    handleOk(status) {
      this.form.status = status;
      if(!this.form.returnCustomerName){
        this.form.returnCustomerName = this.cusomerData.join(',')
      }
      if (!this.form.returnCustomerName) {
        this.$message.error('请输入回款客户名称！')
        return false;
      }
      if(!this.form.invoice){
        this.$message.error('请选择是否开票！')
        return false;
      }
      if (!this.form.sfaSaleContract.type) {
        this.$message.error('请选择是否需要签订合同！')
        return false;
      }
      if (this.form.sfaSaleContract.type == 2 && !this.form.sfaSaleContract.quantity) {
        this.$message.error('请选择签订合同份数！')
        return false;
      }
      if (this.form.sfaSaleContract.type == 2 && !this.form.sfaSaleContract.revertQuantity) {
        this.$message.error('请选择需要合同份数！')
        return false;
      }
      if (this.form.sfaSaleContract.type == 1 && !this.form.sfaSaleContract.notes) {
        this.$message.error('请输入是否需要签订合同备注！')
        return false;
      }
      if (this.form.sfaSaleContract.type == 2) {
        delete this.form.sfaSaleContract.notes
      }
      if (this.form.sfaSaleContract.type == 1) {
        delete this.form.sfaSaleContract.quantity
        delete this.form.sfaSaleContract.revertQuantity
      }
      if (!this.form.sfaSaleDocketProductList || this.form.sfaSaleDocketProductList.length == 0) {
        this.$message.error('请添加卡券！')
        return false;
      }
      if (!this.form.sellingTime) {
        this.$message.error('请选择预期出库时间！')
        return false;
      }
      if (!this.form.expectReturnTime) {
        this.$message.error('请选择预计回款时间！')
        return false;
      }
      let msg = '更新成功！'
      if (this.$route.query.pageType == 'copy') {
        delete this.form.id
        msg = '复制成功！'
      }
      if (this.form.sfaSaleDocketProductList[0].sfaCardCouponList.length == 0 && this.form.sfaSaleDocketProductList[1].sfaCardCouponList.length == 0 && this.form.sfaSaleDocketProductList[2].sfaCardCouponList.length == 0) {
        this.$message.error('请添加卡券！')
        return false;
      }
      this.form.sfaSaleDocketProductList.forEach(item => {
        delete item.id
      })
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.sfaSaleDocketProductList.forEach((item) => {
            item.sfaCardCouponList.forEach(it => {
              // 编辑、复制删除每条id
              delete it.style;
            });
          });
          this.$message.loading();
          console.log(this.form, ' this.form')
          setTimeout(() => {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/save', 'post', this.form).then(res => {
              this.$message.destroy()
              if (res.data.code == 200) {
                this.$message.success(msg);
                this.$router.replace('/sfa_workOrder/OutboundList')
              } else {
                this.$message.error(res.data.message);
              }
            })
          }, 100)
        } else {
          return false;
        }
      });

    },

  }
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

::v-deep .ant-card-body {
  padding: 24px !important;
}

.price-box {
  display: flex;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(85, 66, 246, 0.03);
}

.price-box div {
  padding: 10px 0;
  box-sizing: border-box;
  flex: 1;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #010101;
  text-align: center;
}

.price-box span {
  font-size: 18px;
  color: rgba(255, 0, 0, 1);
}


// ::v-deep .ant-input[disabled] {
//   background: #fff;
//   color: #333;
// }</style>
